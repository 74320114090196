<template>
    <div>
        <div class="vx-row mb-1 mt-3" style="width:70%">
            <div class="vx-col w-full mb-base">
                <vs-button class="mr-3" color="primary" @click="handleCreate()">Create</vs-button>            
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <FilterData
                    @data="(val) => {
                        this.supplierId = val.supplierId
                        this.skuId = val.skuId
                        this.draw = val.draw
                    }"
                ></FilterData>
            </div>
        </div>
        <div class="vx-row mt-1">
            <div class="vx-col w-full mb-base">
                <Table 
                    :draw="draw" 
                    :status="activeTab" 
                    :supplierId="(this.supplierId) ? this.supplierId : 0"
                    :skuId="(this.skuId) ? this.skuId : 0">
                </Table>
            </div>
        </div>

        <vs-prompt
            @cancel="val=''"
            @accept="uploadFile"
            :active.sync="activePrompt"
            :title="'Upload File'"
            :accept-text="'Submit'">
            <div class="con-exemple-prompt">
                <input
                    id="fileInput"
                    name="file"
                    class="w-full inputx"
                    type="file"
                    ref="file"
                    accept=".xlsx, .xls"
                    @change="handleChangeFile"
                />
            </div>
        </vs-prompt>
    </div>
</template>
<script>

import Date from "@/components/Date.vue";
import Table from "./component/Table.vue";
import FilterData from "./component/FilterData.vue";

export default {
    components: {
        Date,
        Table,
        FilterData,
    },
    data() {
        return {
            activeTab: 0,
            draw: 0,
            supplierId: null,
            skuId: null,
            activePrompt : false,
            file : null,
        }
    },
    methods: {
        handleUpload() {
            this.activePrompt = true
        },
        handleCreate() {
            this.$router.push({
                name: this.$store.state.master.purchaseDiscount.baseRouterName + "-create",
            });
        },
        handleChangeFile(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.readAsBinaryString(this.file);
            }
        },
        uploadFile() {
            if (this.file) {
                let form = new FormData();
                form.append("file", this.file);

                this.$vs.loading();
                this.$http
                    .post(this.$store.state.master.buyingPrice.baseUrlPath + "/import", form)
                    .then((resp) => {
                        console.log(resp);
                        if (resp.code == 200) {
                            this.$vs.notify({
                                title: "Success",
                                text: "File Uploaded Successfully",
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });
                        } else {
                            this.$vs.notify({
                                title: "Error",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });
                        }                    
                    });
                this.$vs.loading.close();
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
    },
    watch: {
        // dataFilter() {
        //     console.log(this.dataFilter);
        // },
    },
}
</script>