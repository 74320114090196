<template>
    <vx-card title="Purchase Discount">
        <div class="vx-input-group flex">
            <vs-tabs v-model="$route.params.status">
                <vs-tab label="Draft">
                    <tab-draft></tab-draft>
                </vs-tab>
                <vs-tab label="Release">
                    <tab-release></tab-release>
                </vs-tab>
                <vs-tab label="Log">
                    <tab-log></tab-log>
                </vs-tab>
                <vs-tab label="Expired">
                    <tab-expired></tab-expired>
                </vs-tab>
                <vs-tab label="Canceled">
                    <tab-canceled></tab-canceled>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabDraft from "./TabDraft.vue";
import TabRelease from "./TabRelease.vue";
import TabLog from "./TabLog.vue";
import TabExpired from "./TabExpired.vue";
import TabCanceled from "./TabCanceled.vue";
export default {
    components: {
        TabDraft,
        TabRelease,
        TabLog,
        TabExpired,
        TabCanceled,
    },
    data() {
        return {
            activeTab: "Draft",
        }
    },
    methods: {

    }
}
</script>