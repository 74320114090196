<template>
    <div class="vx-row ml-1 mt-3">
        <div class="vx-row mb-6" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <Supplier :value="selectedSupplier" base-url="/api/wms/v1/master/suppliers" 
                    :custom-label="customSupplier" 
                    track-by="name" 
                    :multiple="true" 
                    :allowEmpty="false"
                    @input="handleSupplierChange"
                    @selected="(val) => {
                        this.selectedSupplier = val;
                    }">
                </Supplier>
            </div>
        </div>
        <div class="vx-row mb-6" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>SKU</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <Sku ref="skuComponent" :value="selectedSku" :base-url="generateItemApiUrl()" 
                    :custom-label="customSku" track-by="id" 
                    :multiple="true" 
                    :allowEmpty="false"
                    @selected="(val) => {
                        this.selectedSku = val;
                    }">
                </Sku>
            </div>
        </div>
        <div class="vx-row mb-1" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full flex items-center">
            </div>
            <div class="vx-col sm:w-3/4 w-full">
                <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import Supplier from "./Supplier.vue";
import Sku from "./Items.vue";
export default {
    components: {
        Supplier,
        Sku,
    },
    mounted() {
        let a = {
            id: 0,
            code: "All",
            name: ""
        }
        let b = {
            id: 0,
            sku_code: "All",
            name: ""
        }
        if (this.selectedSupplier.length == 0) {
            this.selectedSupplier = [a]
        }

        if (this.selectedSku.length == 0) {
            this.selectedSku = [b]
        }
    },
    data() {
        return {
            draw: 0,
            selectedSupplier: [],
            selectedSku: [],
            selectedSupplierIDs: "0",
            selectedSkuIDs: "0",
        }
    },
    methods: {
        customSku({ sku_code, name }) {
            return `${sku_code}  ${name}`;
        },
        customSupplier({ code, name }) {
            return `${code}  ${name}`;
        },
        handleFilter() {
            this.draw++;
            this.emitData()
        },
        handleSupplierChange(newSupplier) {
            this.selectedSku = [];
            this.updateSkuOptions();
        },
        generateItemApiUrl() {
            const supplierId = this.selectedSupplierIDs;
            console.log('Supplier',supplierId)
            return `/api/wms/v1/master/items/multi-supplier?supplier_ids=${supplierId}`;
        },
        updateSkuOptions() {
            this.$nextTick(() => {
                // Method untuk memuat ulang data SKU dengan URL baru
                this.$refs.skuComponent.get();
            });
        },
        emitData() {
            this.$emit("data", {
                draw: this.draw,
                supplierId: this.selectedSupplierIDs,
                skuId: this.selectedSkuIDs
            });
        },
    },
    watch: {
        selectedSupplier(v) {
            var supplierIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) supplierIds.push(e.id) });
            }
            this.selectedSupplierIDs = supplierIds.join(",");
            this.updateSkuOptions();
        },
        selectedSku(v) {
            var skuIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) skuIds.push(e.id) });
            }
            this.selectedSkuIDs = skuIds.join(",")
        }
    },
}
</script>