<template>
    <div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <FilterData
                    @data="(val) => {
                        this.supplierId = val.supplierId
                        this.skuId = val.skuId
                        this.draw = val.draw
                    }"
                ></FilterData>
            </div>
        </div>
        <div class="vx-row mt-1">
            <div class="vx-col w-full mb-base">
                <Table 
                    :draw="draw" 
                    :status="activeTab" 
                    :supplierId="(this.supplierId) ? this.supplierId : 0"
                    :skuId="(this.skuId) ? this.skuId : 0">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import Date from "@/components/Date.vue";
import Table from "./component/Table.vue";
import FilterData from "./component/FilterData.vue";

export default {
    components: {
        Date,
        Table,
        FilterData,
    },
    data() {
        return {
            activeTab: 3,
            draw: 0,
            supplierId: null,
            skuId: null
        }
    },
    methods: {
        // handleCreate() {
        //     this.$router.push({
        //         name: this.$store.state.outbound.simpleSto.baseRouterName + "-create",
        //     });
        // },
    },
    watch: {
        // dataFilter() {
        //     console.log(this.dataFilter);
        // },
    },
}
</script>