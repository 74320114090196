<template>
    <multiselect
        :multiple="multiple"
        :allowEmpty="allowEmpty"
        @search-change="handleSearch"
        :custom-label="customLabel ? customLabel : 'name'"
        :track-by="trackBy ? trackBy : 'id'"
        :disabled="disabled"
        :options="optionsData.length > 0 ? optionsData : (options !== undefined ? options : [])"
        v-model="selected"
        :placeholder="placeholder ? placeholder : 'type to search'"
    >
    </multiselect>
</template>

<script>
export default {
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String
        },
        label: {
            type: String,
        },
        customLabel: {
            type: String,
        },
        baseUrl: {
            type: String,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        trackBy: {
            type: String,
        },
        value: {
        },
        options: {
            required: false
        },
        status: {
            required: false
        },
        selectedItem: {
        }
    },
    data() {
        return {
            typedValue: "",
            timeOuts: null,
            optionsData: [],
            selected: this.value ? this.value : [{
                id: 0,
                sku_code: "All",
                name: ""
            }],
            defaultAll: {
                id: 0,
                sku_code: "All",
                name: ""
            }
        };
    },
    methods: {
        handleSearch(v) {
            this.typedValue = v;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.get, 400);
        },
        get() {
            this.$http
                .get(this.baseUrl, {
                    params: {
                        order: "id",
                        sort: "asc",
                        query: this.typedValue,
                        status: this.status,
                        ids: this.selectedItem,
                    }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        let respData = resp.data.records;
                        console.log('Length received data:', respData);  // Debugging log
                        if (respData == null) {
                            respData = [this.defaultAll];
                        } else {
                            if (respData.length > 1) {
                                respData.unshift(this.defaultAll);
                            }
                        }
                        this.optionsData = respData;
                        console.log("option",this.optionsData)
                    } else {
                        this.optionsData = [];
                        if (this.selected.length >= 0) {
                            this.optionsData.unshift(this.defaultAll);
                        }
                    }
                });
        }
    },
    watch: {
        selected(v) {
            console.log('Selected value changed:', v);  // Debugging log
            this.selected = v;
            console.log("TESSSS",this.optionsData)
            if (v != null) {
                console.log("length V",v.length)
                if (v.length > 0) {
                    if (v[0].id == 0 && v.length > 1) {
                        this.selected.shift();
                    } else if (v.length > 1) {
                        if (this.selected.some(e => e.id === 0)) {
                            this.selected = [{
                                id: 0,
                                sku_code: "All",
                                name: ""
                            }];
                        }
                    }
                } else {
                    this.selected = [{
                        id: 0,
                        sku_code: "All",
                        name: ""
                    }];
                }
            } else {
                this.selected = [{
                    id: 0,
                    sku_code: "All",
                    name: ""
                }];
            }
            this.$emit("selected", this.selected);
        },
        value(v) {
            if (v) {
                console.log('Value prop changed:', v);  // Debugging log
                this.selected = v;
            }
        }
    },
    mounted() {
        if (this.baseUrl != "" && this.baseUrl != undefined) {
            this.get();
        } else if (this.options.length > 0) {
            this.optionsData = this.options.unshift();
        }
    }
};
</script>

<style>
.multiselect--above .multiselect__content-wrapper {
    bottom: auto !important;
    top: 100% !important;
}
</style>
