var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row ml-1 mt-3"},[_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"70%"}},[_vm._m(0),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('Supplier',{attrs:{"value":_vm.selectedSupplier,"base-url":"/api/wms/v1/master/suppliers","custom-label":_vm.customSupplier,"track-by":"name","multiple":true,"allowEmpty":false},on:{"input":_vm.handleSupplierChange,"selected":(val) => {
                    this.selectedSupplier = val;
                }}})],1)]),_c('div',{staticClass:"vx-row mb-6",staticStyle:{"width":"70%"}},[_vm._m(1),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('Sku',{ref:"skuComponent",attrs:{"value":_vm.selectedSku,"base-url":_vm.generateItemApiUrl(),"custom-label":_vm.customSku,"track-by":"id","multiple":true,"allowEmpty":false},on:{"selected":(val) => {
                    this.selectedSku = val;
                }}})],1)]),_c('div',{staticClass:"vx-row mb-1",staticStyle:{"width":"70%"}},[_c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"}),_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('vs-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleFilter()}}},[_vm._v("Filter")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"},[_c('span',[_vm._v("Supplier")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-col sm:w-1/4 w-full flex items-center"},[_c('span',[_vm._v("SKU")])])
}]

export { render, staticRenderFns }